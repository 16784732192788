import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import { GoogleTagManager } from "@next/third-parties/google";
import { Crisp } from "crisp-sdk-web";
import "instantsearch.css/themes/satellite.css";
import { AppProps } from "next/app";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { theme } from "../../config/theme";
import { client } from "../services/client";
import "../styles/main.css";
import NoSsr from "../utils/NoSsr";
const App = ({
  Component,
  pageProps
}: AppProps) => {
  useEffect(() => {
    Crisp.configure(process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID || "", {
      autoload: false
    });
  }, []);
  return <>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <NoSsr>
            <Component {...pageProps} />
          </NoSsr>
        </ChakraProvider>
      </ApolloProvider>
      <GoogleTagManager gtmId="GTM-PWFV6HMZ" />
    </>;
};
export default App;