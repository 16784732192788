import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderWidth: "1px",
    _checked: {
      bg: "#DD5F75",
      borderColor: "#DD5F75",
      _hover: {
        bg: "#DD5F75",
        borderColor: "#DD5F75",
      },
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
