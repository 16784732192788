import { extendTheme } from "@chakra-ui/react";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";

import { buttonTheme } from "./button";
import { checkboxTheme } from "./checkbox";
import { inputTheme } from "./input";

export const theme = extendTheme(CalendarDefaultTheme, {
  initialColorMode: "light",
  useSystemColorMode: false,
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Checkbox: checkboxTheme,
    Calendar: {
      parts: ["calendar"],
      baseStyle: {
        calendar: {
          boxShadow: "none",
        },
      },
    },
    CalendarDay: {
      variants: {
        selected: {
          bgColor: "#DD5F75",
          color: "white",
          _hover: {
            bgColor: "#EA868E",
          },
        },
        range: {
          bgColor: "#DD5F75",
          color: "white",
          _hover: {
            bgColor: "#EA868E",
          },
        },
      },
    },
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  styles: {
    global: {
      ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
        overflow: "initial !important",
      },
    },
  },
  colors: {
    brandPrimary: "#DD5F75",
    brandSecondary: "#FBC8C2",
    brandSecondaryHover: "#FDE6E0",
    lightBrand: "#F4A2A1",
    brandScheme: {
      50: "#FCEFF1",
      100: "#FDE6E0",
      200: "#FBC8C2",
      300: "#F4A2A1",
      400: "#EA868E",
      500: "#DD5F75",
      600: "#BE4565",
      700: "#9F2F58",
      800: "#801E4B",
      900: "#6A1242",
    },
  },
});
