import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";

import { getAuthHeader } from "../utils/getAuthHeader";

const errorLink = onError(({ graphQLErrors, networkError, operation }: ErrorResponse) => {
  if (networkError) {
    console.error(networkError);
  }
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.error(
        "GQL ERROR | Operation:",
        operation.operationName,
        "| Variables:",
        operation.variables,
        "| Error response:",
        err
      );
      if (err?.extensions?.code === "invalid-jwt") {
        console.error("Invalid JWT");
        window.localStorage.clear();
      }
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  const authHeaders = getAuthHeader();
  if (headers && "x-skip-auth" in headers && headers["x-skip-auth"]) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

export const client = new ApolloClient({
  link: from([
    errorLink,
    authLink,
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_HASURA_ENDPOINT,
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "none",
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    },
    query: {
      errorPolicy: "none",
      notifyOnNetworkStatusChange: true,
    },
    mutate: {
      errorPolicy: "none",
    },
  },
});
