import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const md = defineStyle({
  px: "4",
  h: "44px",
  borderRadius: "0.5rem",
});

const lg = defineStyle({
  px: "4",
  h: "46px",
  borderRadius: "0.5rem",
  fontSize: "md",
});

const sizes = {
  md: definePartsStyle({ field: md, addon: md }),
  lg: definePartsStyle({ field: lg, addon: lg }),
};

export const inputTheme = defineMultiStyleConfig({
  sizes,
});
