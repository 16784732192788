import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const brand = defineStyle({
  bg: "#DD5F75",
  color: "white",
  fontWeight: "700",
  _hover: {
    bg: "#EA868E",
    _disabled: {
      bg: "#EA868E",
    },
    _hover: {
      bg: "#EA868E",
    },
  },
  _active: {
    bg: "#DD5F75",
  },
  _loading: {
    bg: "#EA868E",
  },
  _disabled: {
    bg: "#EA868E",
  },
});

const outline = defineStyle({
  color: "#DD5F75",
  fontWeight: "700",
  borderColor: "#DD5F75",
  _hover: {
    bg: "#FDE6E0",
  },
  _active: {
    bg: "#FFFFFF",
  },
});

const link = defineStyle({
  color: "#F4A2A1",
  fontWeight: "600",
  _hover: {
    color: "#FBC8C2",
  },
  _active: {
    color: "#F4A2A1",
  },
});

const sizeMd = defineStyle({
  h: "46px",
  borderRadius: "0.5rem",
});

export const buttonTheme = defineStyleConfig({
  variants: {
    brand,
    outline,
    link,
  },
  sizes: {
    default: sizeMd,
    md: sizeMd,
  },
});
